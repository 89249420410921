// extracted by mini-css-extract-plugin
export var alwaysHidden = "Testimonials-module--always-hidden--47UCU";
export var alwaysShow = "Testimonials-module--always-show--5pG2m";
export var content = "Testimonials-module--content--Vn+Ea";
export var contentNoMargin = "Testimonials-module--content-no-margin--x8qJo";
export var image = "Testimonials-module--image--U1O2C";
export var item = "Testimonials-module--item--ZibBv";
export var logo = "Testimonials-module--logo--LYNlK";
export var person = "Testimonials-module--person--BRsBA";
export var person__noLogo = "Testimonials-module--person__no-logo--5uAvg";
export var quote = "Testimonials-module--quote--cf3sP";
export var testimonialsBlog = "Testimonials-module--testimonials--blog--fPTKS";
export var testimonialsImage = "Testimonials-module--testimonials--image--h-ljh";
export var testimonialsSingleQuote = "Testimonials-module--testimonials--single-quote--JAF0T";
export var wrap = "Testimonials-module--wrap--ZWwdk";