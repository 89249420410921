import React from 'react';

// === Helpers === //
import cn from 'classnames';

// === Styles === //
import * as styles from '@components/sections/Blog/Article/Article.module.scss';

// === Types === //
import { IArticle } from '@components/sections/Blog/Article/Article.d';

const Article = ({ children, className }: IArticle) => {
  return <div className={cn(styles['article'], className && styles[className])}>{children}</div>;
};

export default Article;
