// extracted by mini-css-extract-plugin
export var blogCardButton = "BlogCard-module--blog-card-button--0uF7D";
export var blogCardContent = "BlogCard-module--blog-card-content--QbM92";
export var blogCardDescription = "BlogCard-module--blog-card-description--vMDip";
export var blogCardHeadline = "BlogCard-module--blog-card-headline--+YrMM";
export var blogCardImage = "BlogCard-module--blog-card-image--zXe6s";
export var blogCardInfo = "BlogCard-module--blog-card-info--zv+00";
export var blogCardInfoCategory = "BlogCard-module--blog-card-info-category--s-FoN";
export var blogCardInfoDate = "BlogCard-module--blog-card-info-date--KaJ4w";
export var blogCardInfoOthers = "BlogCard-module--blog-card-info-others--iWvSf";
export var blogCardInfoTime = "BlogCard-module--blog-card-info-time--sTX5T";
export var blogCardWrapper = "BlogCard-module--blog-card-wrapper--R69PQ";