// === Assets === //
import iconClock from '@assets/svg/icon-clock.svg';

// === Components === //
import { Link } from 'gatsby';
import ImageWebp from '@components/common/ImageWebp/ImageWebp';
import ButtonLink from '@components/common/ButtonLink/ButtonLink';

// === Helpers === //
import React from 'react';
import cn from 'classnames';
import dateFormat from 'dateformat';
import * as utils from '@utils';
import { globalTranslations } from '@helpers/globalTranslations';

// === Styles === //
import * as styles from '@components/sections/Blog/BlogCard/BlogCard.module.scss';

// === Types === //
import { IBlogCard } from '@components/sections/Blog/BlogCard/BlogCard.d';

const BlogCard = ({
  category,
  publicDate,
  readingTime,
  image,
  headline,
  textIntro,
  buttonLabel,
  buttonUrl,
}: IBlogCard) => {
  const date = publicDate && publicDate !== '' ? new Date(publicDate) : null;

  const extractContent = (htmlInString: string) => {
    const stringWithoutHtml = htmlInString.replace(/<\/?[^>]+(>|$)/g, '');
    const textLength = 125;

    return (
      stringWithoutHtml.substring(0, textLength) +
      (stringWithoutHtml.length > textLength ? '...' : '')
    );
  };

  return (
    <a href={buttonUrl || '/'}>
      <div className={cn(styles['blogCardWrapper'])}>
        {image && (
          <div className={cn(styles['blogCardImage'])}>
            <ImageWebp
              image={image.url}
              webp={image.webp}
              alt={image.alt}
              title={image.title}
              width="400px"
              height="260px"
            />
          </div>
        )}
        <div className={cn(styles['blogCardContent'])}>
          <div className={cn(styles['blogCardInfo'])}>
            <div className={cn(styles['blogCardInfoCategory'])}>{category}</div>
            <div className={cn(styles['blogCardInfoOthers'])}>
              <div className={cn(styles['blogCardInfoDate'])}>
                {date && dateFormat(date, 'dd.mm.yyyy')}
              </div>
              <div className={cn(styles['blogCardInfoTime'])}>
                <img src={iconClock} alt="" width="14px" height="14px" loading="lazy" />
                {readingTime} {globalTranslations('fieldReadingTimeTranslate')}
              </div>
            </div>
          </div>
          <div className={cn(styles['blogCardHeadline'])}>{utils.safeHtml(headline)}</div>
          {textIntro && textIntro !== '' && (
            <p className={cn(styles['blogCardDescription'])}>{extractContent(textIntro)}</p>
          )}
          <div className={cn(styles['blogCardButton'])}>
            <ButtonLink
              label={buttonLabel || ''}
              href={buttonUrl || '/'}
              variant="outline"
              size="blogCard"
              type="a_self"
              isFake={true}
            />
          </div>
        </div>
      </div>
    </a>
  );
};

export default BlogCard;
