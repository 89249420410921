import React, { Fragment } from 'react';

// === Components === //
import { Swiper, SwiperSlide } from 'swiper/react';
import ImageWebp from '@components/common/ImageWebp/ImageWebp';

// === Helpers === //
import cn from 'classnames';
import * as utils from '@utils';
import { Pagination } from 'swiper';

// === Styles === //
import * as styles from '@components/common/Testimonials/Testimonials.module.scss';
import 'swiper/scss';
import 'swiper/scss/pagination';

// === Types === //
import { ITestimonials } from '@components/common/Testimonials/Testimonials.d';

const Testimonials = ({ items, variant, image, className = '' }: ITestimonials) => {
  const variantClasses = {
    t2_quotes: '',
    t1_quote_image: styles.testimonialsImage,
    t1_quote: styles.testimonialsSingleQuote,
    tblog: styles.testimonialsBlog,
  };

  const variantClass = variantClasses[`t${variant}`];

  const imageComponent = image && (
    <div className={styles.image}>
      <ImageWebp image={image.url} webp={image.webp} alt={image.alt} title={image.title} />
    </div>
  );

  return (
    <>
      <div
        className={cn(
          'hide-on-desktop',
          !image &&
            variant !== '1_quote_image' &&
            (variant === '1_quote' || items.length === 1) &&
            styles['alwaysHidden']
        )}
      >
        <Swiper
          modules={[Pagination]}
          watchSlidesProgress={true}
          slidesPerView={1}
          spaceBetween={24}
          pagination={{ clickable: true }}
        >
          {items?.map((item, index) => (
            <SwiperSlide key={index}>
              <div>
                <div className={styles.quote}>&ldquo;</div>
                <div className={styles.content}>{utils.safeHtml(item.content)}</div>
                <div className={styles.person}>{utils.safeHtml(item.person)}</div>
                <div className={styles.logo}>
                  <ImageWebp
                    image={item.logo.url}
                    webp={item.logo.webp}
                    alt={item.logo.alt}
                    title={item.logo.title}
                    width="134px"
                    height="34px"
                  />
                </div>
              </div>
            </SwiperSlide>
          ))}
          {variant === '1_quote_image' && (
            <SwiperSlide key="img-slide">{imageComponent}</SwiperSlide>
          )}
        </Swiper>
      </div>
      <div
        className={cn(
          'hide-on-mobile',
          !image &&
            variant !== '1_quote_image' &&
            (variant === '1_quote' || items.length === 1) &&
            styles['alwaysShow']
        )}
      >
        <div className={cn(className, styles.wrap, variantClass)}>
          {items?.map((item, index) => (
            <div key={'q' + index} className={styles.quote}>
              “
            </div>
          ))}
          {items?.map((item, index) => (
            <div
              key={'c' + index}
              className={cn(
                styles.content,
                (!item.logo || item.logo.url === '') &&
                  (!item.person || item.person === '') &&
                  styles.contentNoMargin
              )}
            >
              {utils.safeHtml(item.content)}
            </div>
          ))}
          {items?.map((item, index) => (
            <Fragment key={'p' + index}>
              {item.person && item.person !== '' && (
                <div
                  key={'p' + index}
                  className={cn(
                    styles.person,
                    (!item.logo || item.logo.url === '') && styles.person__noLogo
                  )}
                >
                  {utils.safeHtml(item.person)}
                </div>
              )}
            </Fragment>
          ))}
          {items?.map((item, index) => (
            <Fragment key={index}>
              {item.logo && item.logo.url !== '' && (
                <div key={'l' + index} className={styles.logo}>
                  <ImageWebp
                    image={item.logo.url}
                    webp={item.logo.webp}
                    alt={item.logo.alt}
                    title={item.logo.title}
                    width="134px"
                    height="34px"
                  />
                </div>
              )}
            </Fragment>
          ))}
          {variant === '1_quote_image' && imageComponent}
        </div>
      </div>
    </>
  );
};

export default Testimonials;
