import React, { FC } from 'react';

// === Components === //
import ButtonLink from '@components/common/ButtonLink/ButtonLink';
import ImageWebp from '@components/common/ImageWebp/ImageWebp';
import { Link } from 'gatsby';

// === Helpers === //
import cn from 'classnames';
import * as utils from '@utils';

// === Styles === //
import * as styles from '@components/common/Card/Card.module.scss';

// === Types === //
import { ICard } from '@components/common/Card/Card.d';
import { globalTranslations } from '@helpers/globalTranslations';

const Card: FC<ICard> = ({
  title = 'Alphashot Micro v2',
  body,
  image,
  url = '/',
  newTab = false,
  linkCard = false,
  cta,
  className,
  noTrim = false,
  badge,
  onClick,
  active = false,
  fakeButton = false,
}: ICard) => {
  const Card = () => (
    <div
      className={cn(styles['card'], className)}
      onClick={() => {
        onClick && onClick();
      }}
    >
      {badge && (
        <div className={cn(styles['cardBadge'], styles[`cardBadge${badge.type}`])}>
          {badge.text}
        </div>
      )}
      <div className={cn(styles['cardImage'])}>
        {image && image.url && (
          <ImageWebp
            image={image.url}
            webp={image.webp}
            alt={image.alt}
            title={image.title}
            width="338px"
            height="153px"
          />
        )}
      </div>
      {title && title !== '' && <h3 className={cn(styles['cardTitle'])}>{title}</h3>}
      <div className={cn(styles['cardDesc'], noTrim && styles['cardDescNoTrim'])}>
        {noTrim ? utils.safeHtml(body) : body}
      </div>
      {fakeButton ? (
        <div className={cn(styles['fakeButtonWrapper'], styles['cardButtonWrapper'])}>
          <span className={cn(styles['cardButton'], styles['fakeButton'])}>
            {cta?.label || globalTranslations('fieldCtaCardSmall') || ''}
          </span>
        </div>
      ) : (
        <ButtonLink
          wrapperClass={cn(styles['cardButtonWrapper'])}
          customClass={cn(styles['cardButton'])}
          variant="outlineNoHover"
          label={cta?.label || globalTranslations('fieldCtaCardSmall') || ''}
          target={newTab ? '_blank' : '_self'}
          href={cta?.url || '/'}
        />
      )}
    </div>
  );

  return linkCard ? (
    <Link to={url}>
      <Card />
    </Link>
  ) : (
    <Card />
  );
};

export default Card;
