import React from 'react';

// === Components === //
import ImageWebp from '@components/common/ImageWebp/ImageWebp';

// === Helpers === //
import cn from 'classnames';
import * as utils from '@utils';

// === Styles === //
import * as styles from '@components/sections/Blog/BlogTextImage/BlogTextImage.module.scss';

// === Types === //
import { IBlogMedia } from '@DTO/sections/blog/BlogSection/BlogSection.d';

const BlogTextImage = ({ caption, copy, image, variant }: IBlogMedia) => {
  return (
    <div
      className={cn(
        styles['blogTextImageWrapper'],
        copy && copy !== '' && styles['blogTextImageWithCopy'],
        caption && caption !== '' && styles['blogTextImageWithCaption'],
        variant &&
          variant !== '' &&
          styles[`blogTextImageWrapper${utils.capitalizeString(variant)}`]
      )}
    >
      {image && (
        <div className={cn(styles['blogTextImageImage'])}>
          <ImageWebp
            image={image.url}
            webp={image.webp}
            alt={image.alt}
            title={image.title}
            width="536px"
            height="401px"
          />
          {caption && (
            <span
              className={cn(
                styles['blogTextImageCaption'],
                variant &&
                  variant !== '' &&
                  styles[`blogTextImageCaption${utils.capitalizeString(variant)}`]
              )}
            >
              {caption}
            </span>
          )}
        </div>
      )}
      {copy && copy !== '' && (
        <div className={cn(styles['blogTextImageCopy'], 'safe-html-inherit-fonts')}>
          {utils.safeHtml(copy)}
        </div>
      )}
    </div>
  );
};

export default BlogTextImage;
