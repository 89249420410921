import React from 'react';

// === Components === //
import Testimonials from '@components/common/Testimonials/Testimonials';
import Headline from '@components/common/Headline/Headline';

// === Helpers === //
import cn from 'classnames';

// === Styles === //
import * as styles from '@components/sections/Testimonials/Testimonials.module.scss';

const TestimonialsSection = ({
  items,
  image,
  variant,
  headline,
  background,
}: TestimonialsSectionData) => {
  const wrapperClass = variant === 'blog' ? 'blog-page-wrapper' : 'page-wrapper';
  const sectionClass = variant === 'blog' ? styles.TestimonialsSectionWrapperBlog : '';
  return (
    <div
      className={cn(styles['TestimonialsSectionWrapper'], sectionClass, background && 'gray-bg')}
    >
      {!!headline && <Headline className={styles.headline} title={headline} />}
      <div className={wrapperClass}>
        <Testimonials items={items} image={image} variant={variant} />
      </div>
    </div>
  );
};

export default TestimonialsSection;
