// extracted by mini-css-extract-plugin
export var card = "Card-module--card--AzV7+";
export var cardBadge = "Card-module--card-badge---vKbo";
export var cardBadge1 = "Card-module--card-badge-1--AFIK9";
export var cardBadge2 = "Card-module--card-badge-2--nZ-cM";
export var cardBadge3 = "Card-module--card-badge-3--Ardh8";
export var cardButton = "Card-module--card-button--OOs02";
export var cardButtonWrapper = "Card-module--card-button-wrapper--JuYeM";
export var cardDesc = "Card-module--card-desc--qXBSh";
export var cardDescNoTrim = "Card-module--card-desc-no-trim--PyfMC";
export var cardImage = "Card-module--card-image--Q6vbL";
export var cardTitle = "Card-module--card-title--KMEuq";
export var fakeButton = "Card-module--fake-button--qAi72";
export var fakeButtonWrapper = "Card-module--fake-button-wrapper--COuVE";